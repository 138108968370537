<script lang="ts">
  import { type EditableMetadata, type Image } from "bildebanken-model";
  import { isEmpty } from "lodash-es";
  import { onMount } from "svelte";
  import type { StringValuedKeys } from "utils/typescript";
  import EditImageType from "./EditImageType.svelte";
  import EditMediaCreatedOn from "./EditMediaCreatedOn.svelte";
  import EditRights from "./EditRights.svelte";
  import EditVisibility from "./EditVisibility.svelte";
  import type { EditImageActor, EditImageState } from "./editImageMachine";
  import {
    fieldMissingForCurrentImage,
    getAllChanges,
    isRequiredField,
    updateField,
  } from "./editImageMachine";
  import { orgAiContent, orgAiEdit, orgWarn } from "@nrk/origo";
  import { uuidRe } from "utils/fns";

  export let service: EditImageActor;
  export let state: EditImageState;
  export let image: Image;
  export let selectImageOnSave: boolean;
  export let editOnlyMode: boolean = false;
  export let highlightedFields: string[] | undefined;
  export let saveMetadata: () => void;

  function onTextFieldInput(name: StringValuedKeys<EditableMetadata>) {
    return function (this: HTMLInputElement) {
      if (name === "altText" && this.value !== image.metadata.altTextSuggestion) {
        altTextAltered = true;
      }
      updateField(service, name, this.value);
    };
  }

  let altTextExists = image.metadata.altText && image.metadata.altText.length > 0;
  let altTextAltered = false;

  $: altTextSuggestionActive =
    !altTextExists && !state.context.gettingAltTextSuggestion && !altTextAltered && image.metadata.altTextSuggestion;

  $: if (image.metadata.altTextSuggestion && !image.metadata.altText && !altTextAltered) {
    updateField(service, "altText", image.metadata.altTextSuggestion);
    altTextExists = false;
  }

  $: titleMissing = isRequiredField(state, "title") && fieldMissingForCurrentImage(state, "title");

  function saveOnCmdEnter(event: KeyboardEvent) {
    if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      saveMetadata();
    }
  }

  onMount(() => {
    document.addEventListener("keydown", saveOnCmdEnter);
    return () => document.removeEventListener("keydown", saveOnCmdEnter);
  });

  function fieldsChanged() {
    return getAllChanges(state).filter((change) => !isEmpty(change.metadataDelta)).length > 0;
  }

  function beforeUnload(event) {
    if (fieldsChanged()) {
      // Setting the returnValue is what enables the 'Unsaved changes' prompt
      event.returnValue = "";
    }
  }

  // Strip out auto generated GUID title, such as when importing Polopoly images without title
  if (image.metadata.title?.substring(0, 36).match(uuidRe)) {
    image.metadata.title = "";
  }
</script>

<svelte:window on:beforeunload|preventDefault={beforeUnload} />
<div class="wrapper">
  <h2>Om bildet</h2>
  <label>
    Tittel
    <span class={!isRequiredField(state, "title") ? "hidden" : ""}>*</span>
    <input
      class="org-input"
      type="text"
      value={image.metadata.title || ""}
      placeholder="Objektets navn"
      on:input={onTextFieldInput("title")}
      required
    />
    {#if titleMissing}
      <span class="org-warning-copy">{@html orgWarn} Tittel påkrevd</span>
    {/if}
  </label>
  <label>
    Beskrivelse
    <span class={!isRequiredField(state, "description") ? "hidden" : ""}>*</span>
    <textarea
      class="org-input"
      rows="2"
      value={image.metadata.description || ""}
      placeholder="Beskriv hvem, hva og hvorfor til Bildebanken"
      on:input={onTextFieldInput("description")}
    />
  </label>
  <div style="position: relative">
    <label>
      Alternativ tekst
      <span class={!isRequiredField(state, "altText") ? "hidden" : ""}>*</span>
      <div class="suggest-button">
        <textarea
          class={`org-input${!altTextExists && altTextSuggestionActive ? " ai-suggest-input" : ""}`}
          aria-describedby="altTextSuggestion"
          rows="2"
          value={image.metadata.altText || ""}
          placeholder="Beskrivelse for publikum som ikke kan se bildet"
          on:input={onTextFieldInput("altText")}
        />
        {#if state.matches("Editing.Single")}
          {#if state.context.gettingAltTextSuggestion && !image.metadata.altText}
            <span>
              Henter KI-forslag..
              <span style="padding-left:4em;" class="org-spinner" />
            </span>
          {/if}
          {#if altTextAltered && !image.metadata.altText && image.metadata.altTextSuggestion}
            <button class="org-button" on:click={() => (altTextAltered = false)}
              >{@html orgAiEdit} Hent KI-forslag</button
            >
          {/if}
        {/if}
      </div>
      {#if !altTextExists && altTextSuggestionActive}
        <span id="altTextSuggestion" class="ai-suggest-warning"
          >{@html orgAiContent} Forslaget er generert av KI
        </span>
      {/if}
    </label>
    {#if image.metadata.altText && image.metadata.altText.length > 160}
      <div class="org-warning" style="position: relative">
        Over anbefalt lengde på alternativ tekst
      </div>
    {/if}
  </div>

  <div class="row">
    <div class="fill">
      <EditImageType {service} {state} {image} />
    </div>
    <div class="fill">
      <EditVisibility {service} {image} {selectImageOnSave} {editOnlyMode} />
    </div>
  </div>

  <EditRights {service} {image} {state} {highlightedFields} />

  <!-- <PeopleInImageField {state} {service} /> -->
  <div>
    <button type="button">Avansert</button>
    <bb-expand hidden>
      <EditMediaCreatedOn {service} {image} />
    </bb-expand>
  </div>
</div>

<style>
  .suggest-button {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .ai-suggest-warning {
    color: var(--org-color-black);
    background: var(--org-color-unsaved);
    padding: 0.5em 0.5em 0.5em 0.2em;
    border-radius: 2px;
    font-weight: 400;
    font-size: var(--org-font-small, 12px);
    line-height: 1;
  }
  .ai-suggest-input {
    outline-color: var(--org-color-unsaved);
    outline-width: 1px;
    outline-style: dashed;
    box-shadow: none;
  }
  .ai-suggest-input:focus {
    outline-style: none;
  }
  .org-spinner {
    font-size: 0.5em;
    margin-right: 18px;
    padding-top: 3px;
  }

  .row {
    display: flex;
    gap: var(--org-small);
  }

  .row .fill {
    flex: 1;
  }

  .wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--org-medium);
    padding: var(--org-small) var(--org-medium);
    background-color: var(--color-surface-tertiary);
  }

  bb-expand {
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
    padding: var(--org-xsmall);
  }

  .hidden {
    display: none;
  }

  .org-warning-copy {
    background-color: var(--org-color-warning);
    color: var(--org-color-black);
    border-radius: 3px;
    box-sizing: border-box;
    font-size: var(--org-font-small, 12px);
    font-weight: 400;
    line-height: 1;
    padding: 0.5em;
    max-width: fit-content;
  }
</style>
