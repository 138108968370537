import {
  ROLES,
  type Image,
  type Kaleido,
  type Metadata,
  type RightsMarker,
} from "bildebanken-model";
import { pick } from "lodash-es";

const METADATA_FIELDS = ["title", "description", "altText", "usageTerms"] as const;

type MetadataFields = (typeof METADATA_FIELDS)[number];

export type SelectedImage = {
  id: string;
  metadata: Pick<Metadata, MetadataFields> & CreditMetadata;
  technicalMetadata: TechnicalMetadata;
  publications: Publication[];
  source: "Bildebanken" | "Kaleido" | string;
  preview?: PreviewInfo;
};

export type CreditMetadata = {
  credit: {
    imageType?: string; //ImageType
    creator?: string;
    rightsHolder?: string;
  };
  proposedCreditLine?: string;
};

export type Publication = {
  system: string;
  id: string;
};

export type PreviewInfo = {
  url: string;
  height?: number;
  width?: number;
};

export type TechnicalMetadata = {
  mediaType?: string;
  mediaCreatedOn?: string;
  width?: number;
  height?: number;
};

export type PublishedImage = Image & { metadata: { publicId: string } };
export function imageIsPublished(image: Image): image is PublishedImage {
  return !!image.metadata.publicId;
}

function cleanObject(obj) {
  if (typeof obj === "string") {
    return cleanString(obj);
  }
  if (Array.isArray(obj)) {
    return obj.map(cleanObject);
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = cleanObject(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

/**
 * Map image to SelectedImage, requires that the image has a publicId.
 */
export function mapBildebankenImageToSelectedImage(
  image: PublishedImage,
  source: "Bildebanken" | "Kaleido" = "Bildebanken",
): SelectedImage {
  const creator =
    image.metadata.rights?.credit ||
    (image.metadata.contributors?.length && image.metadata.contributors?.length > 0
      ? image.metadata.contributors
          .filter((contributor) => contributor.role.resId === ROLES.photographer)
          .map((contributor) => contributor.contact.title)
          .join("/")
      : undefined);

  const proposedCreditLine = [
    image.metadata.imageType?.replace("Fotografi", "Foto") || "Foto",
    image.metadata.creditLine || "Ukjent",
  ].join(": ");

  const credit = {
    imageType: image.metadata.imageType?.replace("Fotografi", "Foto") || undefined,
    creator: creator || undefined,
    rightsHolder: image.metadata.rights?.credit
      ? undefined
      : image.metadata.rights?.rightsHolder?.contact.title || undefined,
  };

  const publications = [{ system: "Kaleido", id: image.metadata.publicId }];

  const technicalMetadata: TechnicalMetadata = {
    mediaType: image.mediaType,
    mediaCreatedOn: image.metadata.mediaCreatedOn,
    width: image.technicalMetadata?.technical_image_width,
    height: image.technicalMetadata?.technical_image_height,
  };

  const selectedImage = {
    id: image.id,
    metadata: {
      ...pick(image.metadata, METADATA_FIELDS),
      credit,
      proposedCreditLine,
    },
    technicalMetadata,
    publications,
    source,
  };

  return cleanObject(selectedImage);
}

export function kaleidoImageToRightsMarker(image: Kaleido.Metadata): RightsMarker {
  if (image.usage) {
    return "Restricted";
  }

  if (image.rights === "NRK" || image.rights?.toString().startsWith("NRK ")) {
    return "Free";
  }

  if (image.rights) {
    return "Restricted";
  }

  if (image.credit === "NRK" || image.credit?.toString().startsWith("NRK ")) {
    return "Free";
  }

  if (image.credit) {
    return "Restricted";
  }

  return "Unknown";
}

export function kaleidoImageCreditLine(image: Kaleido.Metadata): string | undefined {
  if (image.rights?.match(/([\w\s]+ \/)+ [\w\s]+$/) && image.rights?.includes(image.credit)) {
    return image.rights.toString();
  }

  if (image.creators?.length > 0 && image.credit && !image.credit.includes(" / ")) {
    const creators = image.creators.filter((creator) => !image.credit.includes(creator));
    return creators.concat(image.credit).join(" / ");
  }

  if (image.credit) {
    return cleanString(image.credit);
  }

  if (image.creators?.length > 0 && image.rights) {
    const creators = image.creators.filter((creator) => !image.rights?.includes(creator));
    return creators.concat(image.rights).join(" / ");
  }

  if (image.creators?.length > 0) {
    return image.creators.join(" / ");
  }

  return image.rights || undefined;
}

export function kaleidoImageCopyrightOwners(image: Kaleido.Metadata): string[] | undefined {
  if (!image.credit && !image.rights) {
    return;
  }

  if (image.credit) {
    return image.credit?.split(" / ");
  }

  if (image.rights) {
    return [image.rights];
  }
}

export const cleanString = (input: string | undefined) => input && input.replace(/\0/g, "");
