<script lang="ts">
  import { uniq } from "lodash-es";
  import { searchFilter, updateSearchFilter } from "state/page";
  import { availableSourceSystems, type SourceSystem } from "state/params";

  $: selected = $searchFilter?.sourceSystems ?? [];

  function onChange(this: HTMLInputElement & { value: SourceSystem }) {
    if (this.checked) {
      updateSearchFilter("sourceSystems", uniq(selected.concat(this.value)));
    } else {
      updateSearchFilter(
        "sourceSystems",
        selected.filter((v) => v !== this.value),
      );
    }
  }
</script>

<button type="button">Kilde</button>
<bb-expand hidden={true}>
  <div class="sources">
    {#each availableSourceSystems as source (source)}
      <label>
        <input
          type="checkbox"
          class="org-input"
          checked={selected.includes(source)}
          on:change={onChange}
          value={source}
        />
        {source}
      </label>
    {/each}
  </div>
</bb-expand>

<style>
  .sources {
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
  }
</style>
