import type { Image, Mimir } from "bildebanken-model";
import { getApiUrl } from "config";
import { apiFetch } from "services/fetch-wrapper";
import { ingestKaleidoImageToBildebanken } from "services/kaleido";
import type { Folder, FolderImage, FolderMetadata, UpdateMetadataResponse } from "./types";
import { rootFolderId, type Path } from "services/folders/productions";
import { copyNtbImageToMimir } from "services/ntb";
import { search } from "services/api";
import { notify } from "services/notifications";

export async function importKaleidoImage(image: FolderImage): Promise<FolderImage | undefined> {
  try {
    const mimirImageId = await ingestKaleidoImageToBildebanken(image.id);
    return {
      ...image,
      id: mimirImageId,
      publicId: image.id,
      type: "Mimir",
    };
  } catch (error) {
    console.error("Failed to import Kaleido image for Folder", error);
    notify("Import av bilde fra Kaleido feilet", "warning", 3000);
  }
}
export async function importNtbImage(image: FolderImage): Promise<FolderImage | undefined> {
  try {
    const mimirImageId = await copyNtbImageToMimir(image.id);
    return {
      ...image,
      id: mimirImageId.id,
      publicId: image.id,
      type: "Mimir",
    };
  } catch (error) {
    console.error("Failed to import Ntb image for Folder", error);
    notify("Import av bilde fra NTB feilet", "warning", 3000);
  }
}

export async function createFolder(name: string, parent: string): Promise<Folder> {
  const response = await apiFetch(`${getApiUrl()}/folders`, {
    method: "POST",
    body: JSON.stringify({ name, parent }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not create folder '${name}'. ${response.body}`);
  }

  return response.json();
}

export async function getFolder(folderId: string): Promise<Mimir.Folder> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, { method: "GET" });

  if (!response.ok) {
    throw Error(`Could not fetch folder '${folderId}'. ${response.body}`);
  }

  return response.json();
}

export async function updateFolder(
  folderId: string,
  metadata: Partial<FolderMetadata>,
): Promise<UpdateMetadataResponse> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, {
    method: "PATCH",
    body: JSON.stringify(metadata),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not update folder '${metadata.name}'. ${response.body}`);
  }

  return response.json();
}

export async function deleteFolder(folderId: string) {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}`, { method: "DELETE" });

  if (!response.ok) {
    throw Error(`Could not delete folder '${folderId}'. ${response.body}`);
  }
}

export async function getFolderImages(folderId: string): Promise<FolderImage[]> {
  const response = await search({
    query: "state:new",
    sort: { sortBy: "modifiedOn", sortOrder: "asc" },
    from: 0,
    filter: { folderId },
  });

  const images: FolderImage[] = response.hits.map((img) => {
    return {
      id: img.id,
      publicId: img.metadata.publicId,
      thumbnail: img.thumbnailUrl ?? "",
      title: img.metadata.title ?? "",
      type: "Mimir",
    };
  });

  return images;
}

export async function getFolderContent(
  folderId: string,
): Promise<{ folders: Folder[]; images: FolderImage[] }> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/content`, { method: "GET" });

  if (!response.ok) {
    if (response.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get content for folderId " + folderId);
  }

  const body = await response.json();

  if (!response.ok) {
    throw Error(`Could not get folder content. ${response.body}`);
  }

  return {
    folders: body.folders,
    images: mapImagesToFolderImages(body.images),
  };
}

export async function addItemToFolder(folderId: string, itemId: string) {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/content`, {
    method: "PUT",
    body: JSON.stringify({ id: itemId }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not add item to folder '${folderId}'. ${response.body}`);
  }
}

export async function removeItemFromFolder(folderId: string, itemId: string) {
  const url = new URL(`${getApiUrl()}/folders/${folderId}/content`);
  url.searchParams.append("id", itemId);

  const response = await apiFetch(url.toString(), { method: "DELETE" });

  if (!response.ok) {
    throw Error(`Could not delete item '${itemId}' from folder '${folderId}'. ${response.body}`);
  }
}

export async function getFolderAncestry(folderId: string): Promise<Mimir.AncestryResponse> {
  const response = await apiFetch(`${getApiUrl()}/folders/${folderId}/ancestry`, { method: "GET" });

  if (!response.ok) {
    throw Error(`Could not fetch folder ancestry for folder '${folderId}'. ${response.body}`);
  }

  return response.json();
}

export async function createPath(path: string, rootFolderId: string): Promise<Mimir.PathResponse> {
  const body = { path, rootFolderId };

  const response = await apiFetch(`${getApiUrl()}/folders/path`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error(`Could not create path '${path}' in root '${rootFolderId}'`);
  }

  return response.json();
}

export async function generateProductionFolders(dateList: Date[]): Promise<Path[]> {
  const dates = dateList.map((date) => date.toISOString());
  const response = await apiFetch(`${getApiUrl()}/folders/productions`, {
    method: "POST",
    body: JSON.stringify({ dates, rootFolderId }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error("Error generating production folders");
  }

  return response.json();
}

export async function deleteProductionFolders(dates) {
  const response = await apiFetch(`${getApiUrl()}/folders/productions`, {
    method: "DELETE",
    body: JSON.stringify({ dates, rootFolderId }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (!response.ok) {
    throw Error("Error deleting production folders");
  }
}

function mapImagesToFolderImages(images: Image[]): FolderImage[] {
  return images.map((img) => {
    return {
      id: img.id,
      thumbnail: img.thumbnail,
      title: img.title,
      type: "Mimir",
    };
  });
}
