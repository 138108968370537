<script lang="ts">
  import { orgDate, orgDotdotdot, orgFolder } from "@nrk/origo";
  import RootFolder from "./RootFolder.svelte";
  import { onMount } from "svelte";
  import type { Folder } from "services/folders/types";
  import { deleteProductionFolders, generateProductionFolders } from "services/folders/api";
  import { rootFolderId, type Path } from "services/folders/productions";
  import { add, eachDayOfInterval, format, getWeek, startOfWeek, sub } from "date-fns";
  import { nb } from "date-fns/locale";

  import { currentFolder, recentFolders } from "state/page";
  import { productFruitsInstance } from "services/productFruitsService";

  let showFolders = $recentFolders.length > 0;
  let folderDate = new Date();

  let current: { folder?: Folder; parent?: Folder } = $currentFolder || {};

  let datePickerEl;
  let datePickerDropdownEl;

  const onSelectDate = (event) => {
    folderDate = event.target.date;
    current = {};
    datePickerDropdownEl.hidden = true;
  };

  const folder: Folder = { id: rootFolderId, name: "Produksjoner", hasSubFolder: true };

  $: firstDayOfWeek = folderDate && startOfWeek(folderDate, { locale: nb });

  $: currentWeekDates =
    firstDayOfWeek &&
    eachDayOfInterval({
      start: firstDayOfWeek,
      end: add(firstDayOfWeek, { days: 6 }),
    });

  $: folderFilter = (folder: Folder) =>
    currentWeekDates.map((date) => format(date, "yyyy-MM-dd"))?.includes(folder.name);

  let generateWeekState: Promise<Path[]>;

  const onGenerateWeek = () => {
    const confirmed = confirm(`Vil du opprette mapper for uke ${getWeek(folderDate)} fra ukesmal?`);
    if (confirmed) {
      unsavedChanges = true;
      generateWeekState = generateProductionFolders(currentWeekDates);
    }
  };

  let deleteWeekState: Promise<void> | undefined;

  const onDeleteWeek = () => {
    const confirmed = confirm(`Vil du slette alle mappene for uke ${getWeek(folderDate)}?`);
    if (confirmed) {
      unsavedChanges = true;
      deleteWeekState = deleteProductionFolders(currentWeekDates);
    }
  };

  let hoverHeader = false;
  let shiftKeyPressed = false;
  let advancedMenuOpen = false;

  let showAdvancedButton = false;

  $: {
    if (hoverHeader && shiftKeyPressed) {
      showAdvancedButton = true;
    } else if (!hoverHeader && !advancedMenuOpen) {
      showAdvancedButton = false;
    }
  }

  const setupDatepickerEvents = () => {
    datePickerEl.addEventListener("datepicker.click.day", onSelectDate);
    const now = Date.now();
    datePickerEl.disabled = (date) =>
      date < startOfWeek(sub(now, { years: 1 }), { locale: nb }) ||
      date > startOfWeek(add(now, { months: 6 }), { locale: nb });
    return () => {
      datePickerEl.removeEventListener("datepicker.click.day", onSelectDate);
    };
  };

  const setupKeyboardEvents = () => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        shiftKeyPressed = event.shiftKey;
      }
    };
    window.addEventListener("keydown", listener);
    window.addEventListener("keyup", listener);
    return () => {
      window.removeEventListener("keydown", listener);
      window.removeEventListener("keyup", listener);
    };
  };

  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  let unsavedChanges = false;

  $: {
    unsavedChanges
      ? window.addEventListener("beforeunload", beforeUnload)
      : window.removeEventListener("beforeunload", beforeUnload);
  }
  onMount(() => {
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  });

  onMount(setupDatepickerEvents);
  onMount(setupKeyboardEvents);
</script>

<div>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
  <div
    class="header"
    on:mouseenter={() => (hoverHeader = true)}
    on:mouseleave={() => (hoverHeader = false)}
  >
    <button class="org-button" on:click={() => (showFolders = !showFolders)}>
      <span style="margin-right: .8rem">{@html orgFolder}</span>
      Produksjoner
    </button>
    <div class:hidden={!showFolders}>
      <button class="org-button org-muted icon" class:hidden={!showAdvancedButton}>
        {@html orgDotdotdot}
      </button>
      <bb-dropdown
        hidden
        on:toggle={(event) => {
          advancedMenuOpen = !event.target.hidden;
        }}
      >
        <button class="org-button" on:click={onGenerateWeek}>Generer uke… </button>
        <button class="org-button destructive" on:click={onDeleteWeek}> Slett uke… </button>
      </bb-dropdown>
      <button
        class="org-button icon"
        on:click={() => {
          $productFruitsInstance?.api.events.track("folders-calendar-toggled");
        }}>{@html orgDate}</button
      >
      <bb-dropdown hidden bind:this={datePickerDropdownEl} class="overlay">
        <bb-datepicker bind:this={datePickerEl}></bb-datepicker>
      </bb-dropdown>
    </div>
  </div>
  {#if showFolders}
    <div>
      <RootFolder {folder} {folderFilter} {folderDate} bind:current />
    </div>
  {/if}
</div>

<style>
  .header {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }

  .hidden {
    display: none;
  }

  .icon {
    font-size: 0.75rem;
  }

  .hidden {
    display: none;
  }

  .destructive {
    color: var(--org-color-error);
  }
</style>
