import { type Image } from "bildebanken-model";
import { glassPaneState } from "components/Plugin/GlassPane";
import { getMetadataItem, sendImageToKaleido as publishImageToKaleido } from "services/api";
import { selectImageCallback } from "services/pluginMessages";
import { pageState, showDetails } from "../../state/page";
import {
  imageIsPublished,
  mapBildebankenImageToSelectedImage,
  type PublishedImage,
} from "./imageTypes";
import { appendKaleidoDerivateUrlToImage } from "./services/derivate";
import { productFruitsInstance } from "services/productFruitsService";
import { get } from "svelte/store";
import { copyNtbImageToMimir } from "services/ntb";
import { getKaleidoMetadata } from "services/kaleido";

export async function publishAndSelectNtb(ntbId: string) {
  try {
    glassPaneState.set({ state: "VISIBLE", message: "Henter bilde fra NTB..." });

    const { id } = await copyNtbImageToMimir(ntbId);
    const image = await getMetadataItem(id);

    let publishedImage;
    if (!imageIsPublished(image)) {
      publishedImage = await publishImageToKaleido(id);
    } else {
      publishedImage = image;
    }

    await selectImage(publishedImage);

    pageState.subscribe(() => {
      glassPaneState.set({ state: "HIDDEN" });
    });
  } catch (err) {
    glassPaneState.set({
      state: "SHOW_ERROR",
      message: "Noe gikk galt ved henting fra NTB",
      details: err.message,
    });
  }
}

export async function publishAndSelectKaleido(kaleidoId: string) {
  const publishedImage = (await getMetadataItem(kaleidoId, "Kaleido")) as PublishedImage;
  await selectImage(publishedImage, "Kaleido");
}

export async function publishAndSelect(image: Image): Promise<void> {
  get(productFruitsInstance)?.api.events.track("select-image-requested");
  glassPaneState.set({ state: "VISIBLE", message: "Henter bilde ..." });
  pageState.subscribe((pageState) => {
    if (pageState.type !== "DETAIL") {
      glassPaneState.set({ state: "HIDDEN" });
    }
  });

  try {
    let publishedImage;
    if (!imageIsPublished(image)) {
      glassPaneState.set({ state: "VISIBLE", message: "Kopierer bilde til Kaleido..." });
      publishedImage = await publishImageToKaleido(image.id);
    } else {
      publishedImage = image;
    }
    await selectImage(publishedImage);
    showDetails(image.id);
    glassPaneState.set({ state: "HIDDEN" });
  } catch (err) {
    glassPaneState.set({
      state: "SHOW_ERROR",
      message: "Noe gikk galt",
      details: err.message,
    });
  }
}

async function selectImage(
  publishedImage: PublishedImage,
  imageSource: "Bildebanken" | "Kaleido" = "Bildebanken",
) {
  const selectedImage = mapBildebankenImageToSelectedImage(publishedImage, imageSource);

  // Set width/height/fileType using Kaleido metadata in case it is (not yet) ready from Mimir
  if (
    !selectedImage.technicalMetadata.height ||
    !selectedImage.technicalMetadata.width ||
    !selectedImage.technicalMetadata.mediaType
  ) {
    const metadata = await getKaleidoMetadata(publishedImage.metadata.publicId);
    selectedImage.technicalMetadata = {
      ...selectedImage.technicalMetadata,
      height: metadata.height,
      width: metadata.width,
      mediaType: metadata.format,
    };
  }

  await appendKaleidoDerivateUrlToImage(selectedImage);
  selectImageCallback(selectedImage);
}
