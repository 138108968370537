import { apiFetch } from "../fetch-wrapper";
import { getApiUrl } from "config";

type Captions = {
  caption: {
    nb: string;
    en: string;
  };
};

export async function getGeneratedAltText(itemId: string): Promise<Captions> {
  const response = await apiFetch(`${getApiUrl()}/images/${itemId}/caption`, {
    method: "GET",
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get caption for item " + itemId);
  }

  return response.json();
}

export async function getGeneratedAltTextFromBuffer(data: Buffer): Promise<Captions> {
  const result = await apiFetch(`${getApiUrl()}/images/binary/caption`, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "image/*",
    },
  });

  if (!result.ok) {
    if (result.status === 403) {
      throw Error("No access to this page");
    }
    throw Error("Could not get caption for the provided image data");
  }
  return result.json();
}
