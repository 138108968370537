import { getSelectedIds, getSelectedItems } from "state/SelectionService";
import SelectionService from "state/SelectionService";
import { showDetails } from "state/page";
import { mapSearchHitsToFolderImage } from "services/folders/map";
import { type SearchHit } from "services/searchHit";

export type ToggleCheckboxEvent = { searchHitId: string; checked: boolean };

export interface SearchHitHandler {
  onClickSearchHit(this: HTMLElement, event: MouseEvent): void;
  onDblClickSearchHit(this: HTMLElement, event: MouseEvent): void;
  onToggleCheckbox(event: ToggleCheckboxEvent): void;
  onDragStart(event: DragEvent, searchHits: SearchHit[], draggedSearchHit: SearchHit);
}

function onDragStart(
  event: DragEvent,
  searchHits: SearchHit[],
  draggedSearchHit: SearchHit,
  options: { embedded: boolean; selectionService: typeof SelectionService },
) {
  const state = options.selectionService.getSnapshot();
  if (searchHits) {
    let itemsForFolder: SearchHit[];
    const selectedSearchHits = getSelectedItems(getSelectedIds(state), searchHits);
    const draggedSearchHitInSelectedSearchHits = selectedSearchHits.some(
      (sh) => sh.id === draggedSearchHit.id,
    );
    //Hvordan velge hva som blir dratt
    if (selectedSearchHits.length > 0 && draggedSearchHitInSelectedSearchHits) {
      itemsForFolder = selectedSearchHits;
    } else {
      const itemId = draggedSearchHit.id;
      options.selectionService.send({
        type: "ITEM_CLICKED",
        itemId,
        event,
      });
      itemsForFolder = [draggedSearchHit];
    }

    event.dataTransfer?.setData(
      "text/plain",
      JSON.stringify(mapSearchHitsToFolderImage(itemsForFolder)),
    );
  }
}

export function createBildebankenSearchHitHandler(options: {
  embedded: boolean;
  selectionService: typeof SelectionService;
}): SearchHitHandler {
  function onClickSearchHit(this: HTMLElement, event: MouseEvent) {
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLLabelElement ||
      event.target instanceof HTMLAnchorElement
    )
      return;

    const itemId = this.id;
    if (!itemId) {
      throw Error("Search hit click handler must be attached to an element with an ID attribute");
    }

    if (options.embedded) {
      showDetails(itemId, "Bildebanken");
      return;
    } else {
      const srCheckbox: HTMLInputElement | null = this.querySelector("input[type=checkbox]");
      srCheckbox?.focus();
      options.selectionService.send({
        type: "ITEM_CLICKED",
        itemId,
        event,
      });
    }
  }

  return {
    onClickSearchHit,
    onToggleCheckbox(event: ToggleCheckboxEvent) {
      //FEATURE NOT WORKING AS INTENDED CHECKBOX CHECKING IS CURRENTLY HAPPENING IN onClickSearchHit
      options.selectionService.send({
        type: "TOGGLE_CHECKBOX",
        itemId: event.searchHitId,
        checked: event.checked,
      });
    },
    onDblClickSearchHit(event: MouseEvent) {
      const itemId = this.id;
      if (!itemId) {
        throw Error("Search hit click handler must be attached to an element with an ID attribute");
      }

      event.preventDefault();
      event.stopPropagation();

      showDetails(this.id, "Bildebanken");
    },
    onDragStart(event, searchHits, draggedSearchHit) {
      onDragStart(event, searchHits, draggedSearchHit, options);
    },
  };
}

export function createNtbSearchHitHandler(options: {
  embedded: boolean;
  selectionService: typeof SelectionService;
}): SearchHitHandler {
  return {
    onClickSearchHit(this: HTMLElement, event: MouseEvent) {
      const itemId = this.id;
      if (!itemId) {
        throw Error("Search hit click handler must be attached to an element with an ID attribute");
      }

      if (options.embedded) {
        showDetails(itemId, "Ntb");
        return;
      } else {
        const srCheckbox: HTMLInputElement | null = this.querySelector("input[type=checkbox]");
        srCheckbox?.focus();
        options.selectionService.send({
          type: "ITEM_CLICKED",
          itemId,
          event,
        });
      }
    },
    onDblClickSearchHit(event: MouseEvent) {
      const itemId = this.id;
      if (!itemId) {
        throw Error("Search hit click handler must be attached to an element with an ID attribute");
      }

      event.preventDefault();
      event.stopPropagation();

      showDetails(itemId, "Ntb");
    },
    onToggleCheckbox(event: ToggleCheckboxEvent) {
      //FEATURE NOT WORKING AS INTENDED CHECKBOX CHECKING IS CURRENTLY HAPPENING IN onClickSearchHit
      options.selectionService.send({
        type: "TOGGLE_CHECKBOX",
        itemId: event.searchHitId,
        checked: event.checked,
      });
    },
    onDragStart(event, searchHits, draggedSearchHit) {
      onDragStart(event, searchHits, draggedSearchHit, options);
    },
  };
}
export function createKaleidoSearchHitHandler(options: {
  embedded: boolean;
  selectionService: typeof SelectionService;
}): SearchHitHandler {
  return {
    onClickSearchHit(this: HTMLElement, event: MouseEvent) {
      const itemId = this.id;
      if (!itemId) {
        throw Error("Search hit click handler must be attached to an element with an ID attribute");
      }

      if (options.embedded) {
        showDetails(itemId, "Kaleido");
        return;
      } else {
        const srCheckbox: HTMLInputElement | null = this.querySelector("input[type=checkbox]");
        srCheckbox?.focus();
        options.selectionService.send({
          type: "ITEM_CLICKED",
          itemId,
          event,
        });
      }
    },
    onDblClickSearchHit(event: MouseEvent) {
      const itemId = this.id;
      if (!itemId) {
        throw Error("Search hit click handler must be attached to an element with an ID attribute");
      }

      event.preventDefault();
      event.stopPropagation();

      showDetails(itemId, "Kaleido");
    },
    onToggleCheckbox(event: ToggleCheckboxEvent) {
      //FEATURE NOT WORKING AS INTENDED CHECKBOX CHECKING IS CURRENTLY HAPPENING IN onClickSearchHit
      options.selectionService.send({
        type: "TOGGLE_CHECKBOX",
        itemId: event.searchHitId,
        checked: event.checked,
      });
    },
    onDragStart(event, searchHits, draggedSearchHit) {
      onDragStart(event, searchHits, draggedSearchHit, options);
    },
  };
}
