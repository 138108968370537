import { getBildebankenHost } from "config";
import { getEnvironmentFromHostname } from "utils/fns";
import type { Folder } from "./types";

//Duplicate type def. Path. Move to model?
export type Path = { path: string; id?: string };

export const isoDateRegex = /\d{4}-([0][1-9]|1[0-2])-([0][1-9]|[1-2]\d|3[01])/;

const DayOfWeek = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};

const weekdays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
];
const weekend = [DayOfWeek.Saturday, DayOfWeek.Sunday];
const everyday = [...weekdays, ...weekend];

const shows: Map<string, { days: number[]; hour: number }> = new Map([
  ["Nyhetsmorgen", { days: weekdays, hour: 6 }],
  ["Sendedesken", { days: everyday, hour: 9 }],
  ["Helgemorgen", { days: weekend, hour: 9 }],
  ["Tegnspråknytt", { days: weekdays, hour: 17 }],
  ["Dagsnytt 18", { days: weekdays, hour: 18 }],
  ["Dagsrevyen", { days: everyday, hour: 19 }],
  ["Urix", { days: [DayOfWeek.Wednesday], hour: 20 }],
  ["Dagsrevyen 21", { days: everyday, hour: 21 }],
  ["Debatten", { days: [DayOfWeek.Tuesday, DayOfWeek.Thursday], hour: 21 }],
  ["Kveldsnytt", { days: everyday, hour: 23 }],
  ["HK-publisering TEST", { days: everyday, hour: 23.5 }],
]);

export const rootFolderIds = {
  stage: {
    productions: "8eb66c9b-648d-46db-bf22-82476dbfeb2b",
    album: "46737744-6412-4db5-a835-d789c0cdcc4b",
  },
  prod: {
    productions: "ff4cd9ac-31de-47a4-a77f-470e0e143f03",
    album: "e626faee-7bfb-44e0-a6fd-41c137a92a04",
  },
};

export const rootFolderId = ["prod", "beta"].includes(
  getEnvironmentFromHostname(getBildebankenHost().hostname),
)
  ? rootFolderIds.prod.productions
  : rootFolderIds.stage.productions;

export const sortDailyShows = (a: Folder, b: Folder) => {
  return (shows.get(a.name)?.hour || 1) - (shows.get(b.name)?.hour || 0);
};
