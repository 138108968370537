import {
  QueryClient,
  createMutation,
  createQuery,
  getQueryClientContext,
  type InfiniteData,
} from "@tanstack/svelte-query";
import type { Image } from "bildebanken-model";
import { getMetadataItem, search, sendImageToKaleido } from "services/api";
import type { MimirSearchHit, SearchHit, SuccessfulSearchResult } from "services/searchHit";
import { getMimirVisibility, parseOptionalDate } from "services/searchHit";
import { getContext, setContext } from "svelte";
import { derived, get, type Readable } from "svelte/store";
import { getDetailLink, searchParams, type ImageSource } from "../page";
import { type SearchParams } from "../params";
import { getFallbackImageUrl, getThumbnailUrl } from "../thumbnails";
import type { SearchHitStore, SearchQuery, SearchQueryKey, SearchQueryResult } from "./index";
import { createSearchQuery } from "./index";

export type MimirSearchQueryResult = SearchQueryResult<MimirSearchHit>;
const mimirSearchHitsKey = Symbol("mimir-search-hits-key");

export function setMimirSearchContext(hits$: SearchHitStore<MimirSearchHit>) {
  setContext(mimirSearchHitsKey, hits$);
}

export function getMimirSearchHits(): SearchHitStore<MimirSearchHit> {
  return getContext(mimirSearchHitsKey);
}

const mimirSearchQueryContextKey = Symbol("mimir-search-query-key");

export function setMimirSearchQuery(query: SearchQuery<MimirSearchHit>) {
  setContext(mimirSearchQueryContextKey, query);
}

export function getMimirSearchQuery(): SearchQuery<MimirSearchHit> {
  return getContext(mimirSearchQueryContextKey);
}

function mimirSearchQueryKey(params: SearchParams): SearchQueryKey {
  return ["images", params];
}

export function createMimirSearchQuery(params: SearchParams): SearchQuery<MimirSearchHit> {
  return createSearchQuery(params, search, mimirSearchQueryKey);
}

/** Set up search query, should be called once in the app */

export function getMimirSearchHitIds(): Readable<string[]> {
  const hits = getMimirSearchHits();
  return derived(hits, (value) => value.map((hit) => hit.id));
}

/**
 * Delete image from search results.
 *
 * Useful when image is deleted from database but still visible in search index.
 */
export function hideMimirSearchResult(client: QueryClient, id: string) {
  client.setQueryData(
    mimirSearchQueryKey(get(searchParams)),
    (result?: InfiniteData<SuccessfulSearchResult<MimirSearchHit>>) => {
      if (!result) return result;

      return {
        ...result,
        pages: result.pages.map((page) => ({
          ...page,
          hits: page.hits.filter((hit) => hit.id !== id),
        })),
      };
    },
  );
}

function imageToSearchHit(image: Image): MimirSearchHit {
  const itemState =
    image.itemState === "error" || image.itemState === "complete" || image.itemState === "new"
      ? image.itemState
      : "error";
  return {
    id: image.id,
    state: itemState,
    thumbnailUrl: getThumbnailUrl(image),
    previewUrl: image.proxy || getFallbackImageUrl(),
    itemCreatedOn: parseOptionalDate(image.metadata.createdOn),
    mediaCreatedOn: parseOptionalDate(image.metadata.mediaCreatedOn),
    detailPageUrl: getDetailLink(image.id),
    metadata: {
      title: image.metadata.title || image.originalFileName,
      rightsMarker: image.metadata.rightsMarker || "Unknown",
      description: image.metadata.description,
      publicId: image.metadata.publicId,
      usageTerms: image.metadata.usageTerms,
    },
    type: "Mimir",
    createdBy: image.createdBy,
    visibleTo: getMimirVisibility(image.visibleTo),
    originalFileName: image.originalFileName,
  };
}

/**
 * Add or update image in search results, after editing or uploading
 */
export function upsertMimirSearchResultsWithImage(client: QueryClient | undefined, image: Image) {
  if (!client) return;
  client.setQueryData(
    mimirSearchQueryKey(get(searchParams)),
    (result?: InfiniteData<SuccessfulSearchResult<MimirSearchHit>>) => {
      if (!result) return result;

      let found = false;
      const newResult = {
        ...result,
        pages: result.pages.map((page) => ({
          ...page,
          hits: page.hits.map((hit) => {
            if (hit.id === image.id) {
              found = true;
              return { ...imageToSearchHit(image) };
            }

            return hit;
          }),
        })),
      };

      if (!found && imageToSearchHit(image) !== undefined) {
        newResult.pages[0].hits = [imageToSearchHit(image), ...newResult.pages[0].hits];
      }

      return newResult;
    },
  );
}

/**
 * Add or update image in search results, after editing or uploading
 */
export function upsertMimirImage(client: QueryClient | undefined, image: Image) {
  if (!client) return;
  client.setQueryData(getImageQueryKey(image.id), (result?: Image) => {
    if (!result) return result;
    return image;
  });
}

const getImageQueryKey = (itemId: string) => ["images", itemId];

/**
 * Query for single image metadata.
 *
 * @param itemId id of image
 * @param options Set enabled false to use for preloading
 */
export function createMetadataQuery(
  itemId: string,
  itemSource: ImageSource,
  options: { enabled: boolean } = { enabled: true },
) {
  return createQuery({
    queryKey: getImageQueryKey(itemId),
    queryFn: () => getMetadataItem(itemId, itemSource),
    staleTime: 5000,
    enabled: options.enabled,
  });
}

export function createPublishImageMutation(itemId: string) {
  const client = getQueryClientContext();
  const key = getImageQueryKey(itemId);
  return createMutation({
    mutationKey: key,
    mutationFn: () => sendImageToKaleido(itemId),
    onSuccess(result) {
      client.setQueryData(key, result);
    },
  });
}

/**
 * Returns thunk for preloading item, including its proxy image
 */
export function createPreloader(searchHit: SearchHit) {
  const query = createMetadataQuery(searchHit.id, "Bildebanken", { enabled: false });

  return async () => {
    if (searchHit.previewUrl) {
      const img = new Image();
      img.src = searchHit.previewUrl;
    }
    if (get(query).isStale) {
      await get(query).refetch();
    }
  };
}

/**
 * Preload item metadata
 */
export function preloadItem(searchHit: SearchHit) {
  const preloader = createPreloader(searchHit);
  return preloader();
}
