<svelte:options immutable />

<script lang="ts">
  import { onMount } from "svelte";
  import { formatRelativeDate } from "utils/fns";

  export let timestamp: Date | undefined | null;
  export let label: "Fotografert" | "Lastet opp";

  let relativeDate: string;

  const updateTimeStampInterval = 10 * 1000; // 10s
  const title = `${label} ${timestamp?.toLocaleDateString("no-NB", {
    dateStyle: "medium",
  })} ${timestamp?.toLocaleTimeString("no-NB", { timeStyle: "short" })}`;

  function updateDate(date?: Date | null) {
    if (date) {
      relativeDate = formatRelativeDate(date) || "-";
    }
  }

  onMount(() => {
    let interval: number;
    try {
      updateDate(timestamp);
      interval = window.setInterval(() => {
        updateDate(timestamp);
      }, updateTimeStampInterval);
    } catch (err) {
      console.error("date:", timestamp, err);
    }

    return () => clearInterval(interval);
  });

  // because the date can change depending on context
  $: updateDate(timestamp);
</script>

{#if timestamp}
  <span {title}>{relativeDate}</span>
{/if}
