<svelte:options immutable />

<script lang="ts">
  import { useSelector } from "@xstate/svelte";
  import { type SearchHit } from "services/searchHit";
  import SelectionService, { isItemSelected } from "state/SelectionService";
  import { tilesImageSize } from "../imageSize";
  import { infiniteScroll } from "../infiniteScroll";
  import type { SearchHitHandler } from "../searchHitHandler";
  import { sortParams } from "state/page";
  import ImageTile from "./ImageTile.svelte";
  import { isEmbedded } from "../../../config.js";

  export let searchHits: SearchHit[];
  export let getMore: () => Promise<void>;
  export let handler: SearchHitHandler;
  export let onKeyDown: (event: KeyboardEvent) => void;
  export let createPreloader: (searchHit: SearchHit) => () => Promise<void>;

  function drag(e: CustomEvent<{ event: DragEvent; searchHit: SearchHit }>) {
    handler.onDragStart(e.detail.event, searchHits, e.detail.searchHit);
  }
  $: sortBy = $sortParams.sortBy as "mediaCreatedOn" | "createdOn";
</script>

<div
  class="container"
  style={`--scale: ${isEmbedded() ? 1 : $tilesImageSize}; --show-description: ${
    $tilesImageSize > 0.5 ? 1 : 0
  };`}
  use:infiniteScroll={searchHits && getMore}
>
  {#each searchHits as searchHit}
    <!-- Explicitly wrap item in key block to load
         and render elements in ascending order  -->
    {#key searchHit.id}
      <ImageTile
        {searchHit}
        {handler}
        {sortBy}
        on:drag_start={drag}
        selected={useSelector(SelectionService, isItemSelected(searchHit.id))}
        selectable={true}
        preloader={createPreloader(searchHit)}
        on:keydown={onKeyDown}
      />
    {/key}
  {/each}
</div>

<style>
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(250px * var(--scale)), 1fr));
    gap: var(--org-xsmall);
    width: 100%;
    align-content: flex-start;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 1024px) {
    .container {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      max-height: unset;
      overflow: unset;
    }
  }
</style>
