import type { SearchHit } from "services/searchHit";
import { mapSearchHitToFolderImage } from "./map";
import { updateFolderParam, updateSearchFilter } from "state/page";

export function dragImageStart(event: DragEvent, searchHit: SearchHit) {
  event.dataTransfer?.setData("text/plain", JSON.stringify(mapSearchHitToFolderImage(searchHit)));
}

export async function setFolderFilter(folderId: string): Promise<void> {
  updateSearchFilter("folderId", folderId);
  updateFolderParam(folderId);
}
