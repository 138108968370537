<script lang="ts">
  import { pageState, returnToDetails } from "state/page";
  import Edit from "./Editor/Edit.svelte";
  import FilterMenu from "./Filter/FilterMenu.svelte";
  import CompactDetail from "./Plugin/CompactDetail.svelte";
  import CompactTopBar from "./Plugin/CompactTopBar.svelte";
  import SearchResults from "./SearchPage/SearchResults.svelte";
  import Upload from "./Upload/Upload.svelte";
  import { productFruitsInstance } from "services/productFruitsService";
  import { onMount } from "svelte";
  import SideMenu from "./SideMenu.svelte";
  import FrontPage from "./Plugin/FrontPage.svelte";

  onMount(() => $productFruitsInstance?.api.events.track("plugin-loaded"));
</script>

<CompactTopBar />
<main>
  {#if $pageState.type === "UPLOAD"}
    <Upload />
  {:else if $pageState.type === "EDIT"}
    {#key $pageState.itemIds}
      <Edit
        itemIds={$pageState.itemIds}
        onCancel={returnToDetails($pageState.itemIds)}
        onDone={returnToDetails($pageState.itemIds)}
        selectImageAfterEdit={$pageState.selectImageAfterEdit}
        highlightedFields={$pageState.highlightedFields}
      />
    {/key}
  {:else if $pageState.type === "DETAIL"}
    <SideMenu />
    <CompactDetail itemId={$pageState.itemId} itemSource={$pageState.itemSource ?? "Bildebanken"} />
  {:else if $pageState.type === "LANDING_PAGE"}
    <SideMenu />
    <FrontPage />
  {:else}
    <SideMenu />
    <SearchResults />
  {/if}
</main>

<style>
  main {
    height: 100%;
    display: flex;
    flex-direction: row;

    flex: 1;
    min-height: 0; /* to move scrolling to images-container instead of whole page */
    overflow: auto; /* gives proper scrolling for detail page (tall images for instance) */
  }
</style>
