<script lang="ts">
  import { rootFolderIds } from "services/folders/productions.js";
  import { setFolderFilter } from "services/folders/func";
  import { orgFolder } from "@nrk/origo";
  import { getFolder, getFolderAncestry } from "../../services/folders/api.js";
  import Folder from "../Folders/Folder.svelte";
  import { format } from "date-fns";
  import { nth } from "lodash-es";
  import { nb } from "date-fns/locale";

  export let relevantFolderId;

  const folderNameWithDate = async () => {
    const ancestry = await getFolderAncestry(relevantFolderId);
    const folders = ancestry[relevantFolderId].paths[relevantFolderId].pop();
    const productionSubfolder = folders?.findIndex(
      (folder) => folder.id === rootFolderIds.stage.productions,
    );
    if (productionSubfolder) {
      const production = nth(folders, -1)?.name;
      const parent = nth(folders, -2);
      const formattedDate =
        (parent && format(new Date(parent.name), "d. MMMM", { locale: nb })) || "";
      return `${production} ${formattedDate}`;
    }
    return undefined;
  };

  let showTooltip = false;
</script>

<div style="width: 200px">
  {#await folderNameWithDate() then folderName}
    <div style="position: relative">
      <button
        class="org-button icon folderTitle"
        on:click={() => setFolderFilter(relevantFolderId || "")}
        on:mouseover={() => (showTooltip = true)}
        on:mouseout={() => (showTooltip = false)}
        on:focus={() => (showTooltip = true)}
        on:blur={() => (showTooltip = false)}
      >
        <span style="font-size: .65rem">{@html orgFolder}</span>
        {#await getFolder(relevantFolderId || "") then folder}
          {folder?.name || "Ukjent mappenavn"}
        {/await}
      </button>
      {#if showTooltip}<div id="tooltip" class="tooltip">{folderName}</div>{/if}
    </div>
  {/await}
  <div class="folder">
    <a
      href={`images?folderId=${relevantFolderId}`}
      on:click={() => setFolderFilter(relevantFolderId || "")}
    >
      <Folder
        folder={{
          id: relevantFolderId || "",
          name: "",
          hasSubFolder: true,
        }}
        contentOnly={true}
      />
    </a>
  </div>
</div>

<style>
  .folder {
    width: 150px;
    max-height: 140px;
    overflow: hidden;
    border-radius: var(--org-medium);
  }

  .icon {
    display: flex;
    align-items: center;
    gap: var(--org-xsmall);
  }

  .folderTitle {
    width: 150px;
  }

  .tooltip {
    position: absolute;
    left: 5rem;
    color: var(--color-text-primary);
    background-color: var(--color-surface-primary);
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 9999;
    transform: translateX(-50%);
  }
</style>
