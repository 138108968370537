import { createInfiniteQuery, type InfiniteQueryObserverResult } from "@tanstack/svelte-query";
import { uniqBy } from "lodash-es";
import type { SearchHit, SuccessfulSearchResult } from "services/searchHit";
import { derived, type Readable } from "svelte/store";
import { diffingDerivedStore } from "../../utils/fns";
import { PAGE_SIZE, type SearchParams } from "../params";

export type SearchQueryResult<T extends SearchHit> = InfiniteQueryObserverResult<
  SuccessfulSearchResult<T>
>;
export type SearchHitStore<T extends SearchHit> = Readable<T[]>;
export type SearchQuery<T extends SearchHit> = Readable<SearchQueryResult<T>>;
export type SearchQueryKey = [string, SearchParams];

export function createSearchQuery<T extends SearchHit>(
  params: SearchParams,
  search: (params: SearchParams, signal?: AbortSignal) => Promise<SuccessfulSearchResult<T>>,
  queryKeyGen: (params: SearchParams) => SearchQueryKey,
): SearchQuery<T> {
  return createInfiniteQuery({
    queryKey: queryKeyGen(params),
    refetchInterval: params.filter.folderId ? 5000 : false,
    queryFn: (context) =>
      search({ ...context.queryKey[1], from: context.pageParam || 0 }, context.signal),
    getNextPageParam: (lastPage) => ((lastPage && lastPage.from) || 0) + PAGE_SIZE,
    refetchOnWindowFocus: false,
  });
}

export function getSearchHitsFromQuery<T extends SearchHit>(query: SearchQuery<T>): Readable<T[]> {
  let prev = [] as T[];
  return derived(query, (result) => {
    if (result.isLoadingError) {
      prev = [];
    } else if (result.isSuccess) {
      prev = uniqBy(result.data?.pages.flatMap((page) => page.hits), (hit) => hit.id);
    }
    return prev;
  });
}

export function getCountFromQuery(query: SearchQuery<SearchHit>): Readable<number> {
  return diffingDerivedStore(query, (result) => result.data?.pages[0]?.total || 0);
}
