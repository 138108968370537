<script lang="ts">
  import { orgGarbage } from "@nrk/origo";
  import { createMutation, createQuery, useQueryClient } from "@tanstack/svelte-query";
  import {
    addItemToFolder as addImageToFolder,
    getFolderImages,
    importKaleidoImage,
    importNtbImage,
    removeItemFromFolder,
  } from "services/folders/api";
  import type { FolderImage } from "services/folders/types";
  import { onDestroy } from "svelte";
  import { fade } from "svelte/transition";
  import { sentryCaptureException } from "services/sentry";
  import { productFruitsInstance } from "services/productFruitsService";
  import { notify } from "services/notifications";
  import { getDetailLink } from "state/page";
  import { getBildebankenHost } from "config";

  export let folderId: string;

  let draggingOver = false;
  let draggingImage = false;
  let dragOverRemoveDropZone = false;
  let deleteElement;

  let timeStampLastMutation = 0;

  const queryClient = useQueryClient();

  const thumbnailCache = new Map<string, string>();

  const query = createQuery({
    queryKey: ["folderImages", folderId],
    refetchInterval: 5000,
    queryFn: async (_) => {
      const newData = await getFolderImages(folderId);
      if (getTimeSinceLastMutationInSeconds() < 10) {
        // Less than 10 seconds since mutation, skip update and use local data to avoid added images disappearing / deleted images reappearing
        return $query.data;
      }
      return newData;
    },
    refetchOnWindowFocus: false,
  });

  const addImageMutation = createMutation({
    mutationFn: async (mutationData: { folderId: string; image: FolderImage }) => {
      let imageToBeAdded: FolderImage | undefined;

      if (mutationData.image.type === "Kaleido") {
        imageToBeAdded = await importKaleidoImage(mutationData.image);
      } else if (mutationData.image.type === "Ntb") {
        imageToBeAdded = await importNtbImage(mutationData.image);
      } else {
        imageToBeAdded = mutationData.image;
      }

      timeStampLastMutation = Date.now();

      if (!imageToBeAdded) {
        notify(`Klarte ikke å legge bilde til i mappe`, "warning", 3000);
        return;
      }

      await addImageToFolder(mutationData.folderId, imageToBeAdded.id);
      if (imageToBeAdded.thumbnail) {
        thumbnailCache.set(imageToBeAdded.id, imageToBeAdded.thumbnail);
      }
      return imageToBeAdded;
    },
    onMutate: (newItem) => {
      timeStampLastMutation = Date.now();

      // Optimistic add image to folder
      queryClient.setQueryData(["folderImages", folderId], (previous: FolderImage[]) => {
        return [...(previous ?? []), newItem.image];
      });
    },
    onError: async (error) => {
      notify(`Klarte ikke å legge bilde i mappe`, "warning", 3000);
      console.error("Error adding item", error);
      sentryCaptureException(error);
    },
    onSuccess(addedImage) {
      queryClient.setQueryData(["folderImages", folderId], (previous: FolderImage[]) =>
        previous.map((image) => {
          if (image.id === addedImage?.id || image.id === addedImage?.publicId) {
            return addedImage;
          }
          return image;
        }),
      );
    },
  });

  const removeImageMutation = createMutation({
    mutationFn: async (mutationData: { folderId: string; imageId: string }) => {
      timeStampLastMutation = Date.now();
      await removeItemFromFolder(mutationData.folderId, mutationData.imageId);
    },
    onMutate: async (removedItem) => {
      // Optimistic remove image from folder
      queryClient.setQueryData(["folderImages", folderId], (previous: FolderImage[]) => [
        ...(previous ?? []).filter((image) => image.id !== removedItem.imageId),
      ]);
    },
    onError: async (error) => {
      notify(`Klarte ikke å fjerne bilde fra mappe`, "warning", 3000);
      console.error("Error removing item", error);
      sentryCaptureException(error);
    },
  });

  const getTimeSinceLastMutationInSeconds = () => {
    const timeSinceLastMutation = Date.now() - timeStampLastMutation;
    return Math.floor(timeSinceLastMutation / 1000);
  };

  async function droppedImage(event: DragEvent, targetFolderId: string) {
    $productFruitsInstance?.api.events.track("dropped-image-in-folder");
    draggingOver = false;
    const data = event.dataTransfer?.getData("text/plain");
    const droppedImages: (FolderImage & { folderId?: string })[] = JSON.parse(data ?? "");

    droppedImages.forEach((droppedImage) => {
      if (targetFolderId === droppedImage.folderId) return;
      if (imageAlreadyInFolder(droppedImage)) {
        notify(`Bildet '${droppedImage.title}' er allerede lagt til i mappe`, "warning", 3000);
      } else {
        $addImageMutation.mutate({ folderId: targetFolderId, image: droppedImage });
      }
    });
  }

  const dropImageForRemoval = (event: DragEvent, folderId: string) => {
    $productFruitsInstance?.api.events.track("dropped-image-in-removal-area");
    const data = event.dataTransfer?.getData("text/plain");
    const imageData: FolderImage = JSON.parse(data ?? "")[0];
    $removeImageMutation.mutate({ folderId, imageId: imageData.id });
    draggingImage = false;
    dragOverRemoveDropZone = false;
  };

  onDestroy(() => {
    $query.remove();
  });

  // Reuse thumbnail (for imported images, to avoid flickering)
  const getThumbnail = (image: FolderImage): string => {
    if (thumbnailCache.has(image.id)) image.thumbnail = thumbnailCache.get(image.id);
    return image.thumbnail ?? new URL("/hourglass-icon.svg", getBildebankenHost()).toString();
  };

  const imageAlreadyInFolder = (imageToBeAdded: FolderImage) =>
    $query.data?.find(
      (image: FolderImage) =>
        image.id === imageToBeAdded.id || image.publicId === imageToBeAdded.id,
    );
</script>

<li>
  <div class="folder-container">
    <div
      class="image-container"
      class:dragOverDropZone={draggingOver}
      on:dragover={(ev) => {
        draggingOver = true;
        ev.preventDefault();
      }}
      on:dragleave={() => (draggingOver = false)}
      on:drop={(event) => droppedImage(event, folderId)}
      role="button"
      tabindex="0"
    >
      {#if $query.isLoading}
        <div class="org-spinner image-importing-spinner" />
      {:else if $query.isError}
        <p>Feil</p>
      {:else if $query.isSuccess}
        {#if $query.data}
          {#if $query.data.length === 0}
            <span class="org-muted placeholder-text">Ingen bilder</span>
          {:else}
            {#each $query.data as image (image.id)}
              {#key image.id}
                {#if image.thumbnail}
                  {#if image.type === "Mimir"}
                    <a
                      href={getDetailLink(image.id)}
                      style="text-decoration: none;"
                      draggable="true"
                      on:dragstart={(e) => {
                        e.dataTransfer?.setData(
                          "text/plain",
                          JSON.stringify([{ ...image, folderId: folderId }]),
                        );
                        draggingImage = true;
                      }}
                      on:dragend={(e) => {
                        draggingImage = false;
                      }}
                    >
                      <img class="thumb" src={getThumbnail(image)} alt={image.title} />
                    </a>
                  {:else}
                    <div class="thumbwrapper" draggable="false">
                      <div class="org-spinner image-importing-spinner" />
                      <img
                        draggable="false"
                        class="thumb thumb-loading"
                        src={getThumbnail(image)}
                        alt={image.title}
                      />
                    </div>
                  {/if}
                {:else}
                  <div class="thumb-placeholder">
                    <div class="org-spinner image-importing-spinner" />
                  </div>
                {/if}
              {/key}
            {/each}
          {/if}
        {/if}
      {/if}
    </div>
    {#if draggingImage}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        bind:this={deleteElement}
        class="remove-image-box"
        in:fade={{ delay: 50, duration: 200 }}
        out:fade={{ delay: 50, duration: 200 }}
        class:dragOverDropZone={dragOverRemoveDropZone}
        on:dragover|preventDefault={() => {
          dragOverRemoveDropZone = true;
        }}
        on:dragleave={(event) => {
          if (event.relatedTarget && deleteElement.contains(event.relatedTarget)) return;
          dragOverRemoveDropZone = false;
        }}
        on:drop={(event) => dropImageForRemoval(event, folderId)}
      >
        {@html orgGarbage}
      </div>
    {/if}
  </div>
</li>

<style>
  .remove-image-box {
    width: 100%;
    height: 50px;
    margin-top: var(--org-small);
    border-radius: var(--org-small);
    padding: var(--org-small);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--org-color-shade-2);
  }
  li {
    list-style: none;
    margin-left: var(--org-small);
  }
  .thumb-placeholder {
    width: 40px;
    height: 30px;
    padding: 4px;
    border-radius: 3px;
    background-color: var(--org-color-shade-1);
  }
  .thumb {
    max-width: 50px;
    max-height: 40px;
    padding: 4px;
    border-radius: 3px;
  }
  .thumb-loading {
    filter: brightness(50%);
  }
  .image-container {
    border: none;
    padding: var(--org-small);
    background-color: var(--org-color-shade-1);
    width: 100%;
    min-height: 200px;
    margin-right: var(--org-small);
    border-radius: var(--org-small);
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    gap: var(--org-xsmall);
  }
  .thumbwrapper {
    min-width: 40px;
    min-height: 30px;
    max-width: 50px;
    max-height: 40px;
  }
  .image-importing-spinner {
    font-size: 0.3rem;
    position: relative;
    z-index: 1;
    top: 50%;
    bottom: 50%;
  }
  .org-spinner:after {
    color: white;
  }
  .dragOverDropZone {
    outline: 2px dashed var(--org-color-primary);
    background-color: var(--org-color-shade-2);
  }
  .placeholder-text {
    padding: 1rem;
  }
</style>
