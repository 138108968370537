import { Mimir, ROLES, type Metadata, type Photographer } from "bildebanken-model";
import exifReader from "exifreader";
import { stripNtbDescription } from "./fns";

const KEYS = [
  "rights",
  "Copyright",
  "Copyright Notice",
  "CopyrightOwnerName",
  "Credit",
  "description",
  "Caption/Abstract",
  "title",
  "UsageTerms",
  "Headline",
  "By-line",
  "Creator",
  "DigitalSourceType",
  "DateCreated",
  "Make",
  "Image Width",
  "Image Height",
  "Orientation",
] as const;
type IptcField = (typeof KEYS)[number];
export type MinimalIptcData = Partial<Record<IptcField, string> & { thumbnail?: Buffer }>;

function readIptc(file: File): Promise<MinimalIptcData> {
  if (file instanceof Blob && file.size > 0) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (readerEvent) => {
        if (!(readerEvent.target?.result instanceof ArrayBuffer)) return;

        try {
          const result: MinimalIptcData = {};

          const exifAndIptcData = exifReader.load(readerEvent.target.result);
          const thumbnailImage = exifAndIptcData["Thumbnail"]?.image as Buffer;
          if (thumbnailImage) {
            result.thumbnail = thumbnailImage;
          }
          for (const key of KEYS) {
            const value = exifAndIptcData[key];
            if (value && value.description && typeof value.description === "string") {
              const trimmed = value.description.trim();
              if (trimmed.length > 0) {
                result[key] = trimmed;
              }
            }
          }

          resolve(result);
        } catch (err) {
          // fail silently here, no big deal if this fails
          resolve({});
        }
      };

      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
    });
  } else {
    return Promise.resolve({});
  }
}

const iptcData = new Map<string, MinimalIptcData>();
export async function storeIptc(file: File) {
  iptcData.set(file.name, await readIptc(file));
}
export function linkIptcFilenameAndId(filename: string, id: string) {
  const data = iptcData.get(filename);
  if (data) {
    iptcData.set(id, data);
  } else {
    throw Error("No IPTC data available for that filename");
  }
}

export function getIptc(fileNameOrId: string): MinimalIptcData | undefined {
  return iptcData.get(fileNameOrId);
}

/**
 * Use IPTC fields to extend metadata
 *
 * If metadata contains NrkData, the function attempts to update non-destructively
 * (tweaking of this logic might be needed down the line)
 */
export function enrichMetadataFromIptc(data: MinimalIptcData, metadata: Metadata): Metadata {
  const result: Metadata = { ...metadata };

  const useNtbMapping = data.Credit === "NTB";

  if (
    data.rights ||
    data.UsageTerms ||
    data.Credit ||
    data["Copyright Notice"] ||
    data.CopyrightOwnerName ||
    data.Copyright ||
    data.DigitalSourceType
  ) {
    result.rights = { ...(metadata.rights || {}) };
    result.rights.note = metadata.rights?.note || data.UsageTerms;
    result.rights.credit = metadata.rights?.credit || (useNtbMapping ? undefined : data.Credit);
    result.digitalSourceType = data.DigitalSourceType;

    let rightsContact =
      data.CopyrightOwnerName ||
      (useNtbMapping ? data.Credit : undefined) ||
      data.Copyright ||
      data["Copyright Notice"] ||
      data.rights;

    if (rightsContact?.startsWith("NRK")) {
      rightsContact = "NRK";
    }

    if (rightsContact && !metadata.rights?.rightsHolder) {
      result.rights.rightsHolder = { contact: { title: rightsContact } };
    }
  }

  const creator = data.Creator || data["By-line"];
  if (creator) {
    const photographer: Photographer = {
      contact: {
        title: creator,
      },
      role: {
        resId: ROLES.photographer,
      },
    };

    result.contributors = [...(metadata.contributors || []), photographer];
  }

  let description = data["Caption/Abstract"] || data.description || metadata.description;

  if (description && useNtbMapping) {
    description = stripNtbDescription(description);
  }

  const isPhotography = data.Make !== undefined;
  const screenshotKeywords = ["screenshot", "skjermbilde"];
  const wordInMetadataTitle = (word) => metadata.title?.toLocaleLowerCase().includes(word);
  const isScreenshot = screenshotKeywords.filter(wordInMetadataTitle).length > 0;

  if (isPhotography) {
    result.imageType = "Fotografi";
  } else if (isScreenshot) {
    result.imageType = "Faksimile";
  } else if (metadata.title?.endsWith(".png")) {
    result.imageType = "Grafikk";
  }

  const fileExtensionReg = /\.\w+$/;
  const strippedTitle = metadata.title?.replace(fileExtensionReg, "");

  result.title =
    (useNtbMapping ? data.Headline : undefined) ||
    data.title ||
    data.Headline ||
    data["Caption/Abstract"] ||
    strippedTitle;
  result.headline = data.Headline || metadata.headline;
  result.description = description;
  result.mediaCreatedOn = data.DateCreated;
  result.description = result.description === result.title ? undefined : result.description;

  return result;
}

export function enrichTechnicalMetadataFromIptc(
  iptc: MinimalIptcData,
  mediaType: string,
  technicalMetadata: Mimir.TechnicalMetadata | undefined,
): Mimir.TechnicalMetadata | undefined {
  const width = iptc["Image Width"]?.match(/\d+/)?.pop() || undefined;
  const widthValue = (width && parseInt(width)) || undefined;

  const height = iptc["Image Height"]?.match(/\d+/)?.pop() || undefined;
  const heightValue = (height && parseInt(height)) || undefined;

  const orientation = iptc["Orientation"] || undefined;
  const noRotation = !orientation || orientation === "top-left";

  if (widthValue && heightValue) {
    return {
      ...technicalMetadata,
      technical_image_width: noRotation ? widthValue : heightValue,
      technical_image_height: noRotation ? heightValue : widthValue,
      technical_image_file_type: mediaType,
    };
  }
  return technicalMetadata;
}
