<script lang="ts">
  import { useMachine, useSelector } from "@xstate/svelte";
  import { calculateCreditLine, type Image } from "bildebanken-model";
  import { getPlaceholderCreationFailures, uploads } from "state/upload";
  import EditForm from "./EditForm.svelte";
  import {
    editImageMachine,
    getAllEditedImages,
    getCurrentImage,
    type EditableFieldKey,
  } from "./editImageMachine";
  import EditTopBar from "./EditTopBar.svelte";

  import { getQueryClientContext } from "@tanstack/svelte-query";
  import {
    mapBildebankenImageToSelectedImage,
    type PublishedImage,
  } from "components/Plugin/imageTypes";
  import { isEmbedded } from "../../config";
  import {
    getEditOnlyMode,
    getRequiredFields,
    getSelectImageOnSave,
  } from "../../services/plugin-customization";
  import { closePluginCallback, updateCallback } from "../../services/pluginMessages";
  import { pageState } from "../../state/page";
  import { publishAndSelect } from "../Plugin/publishAndSelect";
  import EditPreview from "./EditPreview.svelte";
  import { appendKaleidoDerivateUrlToImage } from "components/Plugin/services/derivate";

  export let itemIds: string[];
  export let selectImageAfterEdit: boolean = false;
  export let highlightedFields: string[] | undefined = undefined;
  export let requiredFields: EditableFieldKey[] = ["title"];
  export let onCancel: () => void;
  export let onDone: () => void;

  const userDefinedRequiredFields = getRequiredFields();
  if (userDefinedRequiredFields && selectImageAfterEdit) {
    requiredFields = [...requiredFields, ...(userDefinedRequiredFields as EditableFieldKey[])];
  }

  const editOnlyMode = getEditOnlyMode();

  const { state, send, service } = useMachine(
    editImageMachine.withContext({
      images: [],
      originals: [],
      changes: [],
      failedUploadImageIds: [],
      current: 0,
      imageIds: itemIds ?? [],
      requiredFields,
      uploads,
      client: getQueryClientContext(),
      gettingAltTextSuggestion: true,
    }),
  );

  const selectImageOnSave =
    (getSelectImageOnSave() &&
      $state.context.imageIds.length === 1 &&
      $pageState.type === "UPLOAD") ||
    (selectImageAfterEdit && $state.context.imageIds.length === 1 && $pageState.type === "EDIT");

  function mapStateToPublishedImage(imageItem: Image): PublishedImage {
    return <Image & { metadata: { publicId: string } }>{
      ...imageItem,
      metadata: {
        ...imageItem.metadata,
        creditLine: calculateCreditLine(imageItem.metadata),
        publicId: imageItem.metadata.publicId || "",
      },
    };
  }
  async function saveMetadata() {
    service.send({ type: "SUBMIT" });
  }

  const images = useSelector(service, getAllEditedImages);
  const selectedImage = useSelector(service, getCurrentImage);

  function selectImage(imageId: string) {
    send({ type: "SELECT_IMAGE", imageId });
  }

  $: {
    if ($state.matches("Done")) {
      onDone();
      if (isEmbedded()) {
        if (editOnlyMode) {
          const selectedImage = mapBildebankenImageToSelectedImage(
            mapStateToPublishedImage($state.context.images[0]),
          );
          appendKaleidoDerivateUrlToImage(selectedImage).then(() => {
            updateCallback(selectedImage);
            closePluginCallback();
          });
        } else if (selectImageOnSave) {
          publishAndSelect($state.context.images[0]);
        }
      }
    }
  }
</script>

<div class="edit-container">
  {#if $state.matches("Loading")}
    <div class="org-spinner">
      <p>Henter metadata&hellip;</p>
    </div>
  {:else if $state.matches("Error")}
    <div class="error">
      <h2>Noe gikk galt</h2>
      <p>
        <em>{$state.context.error?.message || "Ukjent feil"}</em>
      </p>
      <p><button class="org-button org-button--secondary" on:click={onCancel}>Avbryt</button></p>
    </div>
  {:else if $images.length}
    <EditTopBar
      state={$state}
      {service}
      {onCancel}
      {saveMetadata}
      {selectImageOnSave}
      {editOnlyMode}
    />
    <div class="content-container">
      <div class="image-column" style={!isEmbedded() ? "height: 100vh" : ""}>
        {#each $images as image (image.id)}
          <EditPreview
            state={$state}
            {image}
            selected={!$state.matches("Saving") &&
              ($state.matches("Editing.All") || image.id === $selectedImage.id)}
            onSelect={() => selectImage(image.id)}
          />
        {/each}
        {#each getPlaceholderCreationFailures($uploads) as file (file.file.name)}
          <EditPreview
            image={{ originalFileName: file.file.name }}
            onSelect={() => {}}
            selected={false}
            state={$state}
          />
        {/each}
      </div>

      <section class="edit-form">
        {#key $selectedImage.id}
          <EditForm
            {service}
            state={$state}
            image={$selectedImage}
            {saveMetadata}
            {selectImageOnSave}
            {editOnlyMode}
            {highlightedFields}
          />
        {/key}
      </section>
    </div>
  {/if}
</div>

<style>
  .edit-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }

  .error {
    margin: var(--org-medium) var(--org-large);
  }

  .org-spinner {
    padding: 4rem;
    text-align: center;
  }

  .org-spinner p {
    margin-top: 8rem;
  }

  .content-container {
    display: flex;
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }

  .image-column,
  .edit-form {
    flex: 0 0 50%;
  }

  .image-column {
    display: flex;
    flex-direction: column;
    gap: var(--org-small);
    padding: var(--org-small);
    overflow-y: auto;
  }
</style>
