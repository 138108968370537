<svelte:options immutable />

<script lang="ts">
  export let timestamp: Date | undefined | null;
  export let label: "Fotografert" | "Lastet opp";

  const title = `${label} ${timestamp?.toLocaleDateString("no-NB", {
    dateStyle: "medium",
  })} ${timestamp?.toLocaleTimeString("no-NB", { timeStyle: "medium" })}`;
</script>

{#if timestamp}
  <span {title}>
    {timestamp.toLocaleDateString("no-NB")}
    {timestamp.toLocaleTimeString("no-NB", { timeStyle: "short" })}
  </span>
{/if}
