<script lang="ts">
  import {
    orgChevronClosed,
    orgChevronOpen,
    orgDotdotdot,
    orgExternalLink,
    orgGarbage,
  } from "@nrk/origo";
  import { createQuery } from "@tanstack/svelte-query";
  import { createFolder, deleteFolder, getFolderContent, updateFolder } from "services/folders/api";
  import { expandedFolders, recentFolders, todaysFolderInitiallyOpen } from "state/page";
  // retain module scoped expansion state for each tree node
  import { format } from "date-fns";
  import nbLocale from "date-fns/locale/nb";
  import { isEqual, sortBy, upperFirst, omit, takeRight } from "lodash-es";
  import type { Folder, FolderMetadata } from "services/folders/types";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import EditFolderButton from "./EditFolderButton.svelte";
  import { isoDateRegex, sortDailyShows } from "services/folders/productions";
  import { setFolderFilter } from "services/folders/func";
  import NewFolderButton from "./NewFolderButton.svelte";
  import { isEmbedded } from "config";
  import { productFruitsInstance } from "services/productFruitsService";
  import FolderImages from "./FolderImages.svelte";

  export let folder: Folder;
  export let parent: Folder | undefined = undefined;
  export let current: { folder?: Folder; parent?: Folder } = {};

  export let contentOnly = false;
  $: expanded = contentOnly || $expandedFolders.has(folder.id);

  let parsedTitleDate =
    (folder?.name?.match(isoDateRegex) != undefined && new Date(folder.name)) || undefined;

  let parsedTitle =
    upperFirst(parsedTitleDate && format(parsedTitleDate, "EEEE d. MMMM", { locale: nbLocale })) ||
    folder.name;

  let draggingOverHeader = false;

  const query = createQuery({
    queryKey: ["folders", folder.id],
    refetchInterval: 10000,
    queryFn: async (context) => {
      const folderId = context.queryKey.at(1);
      if (folderId) return await getFolderContent(folderId);
    },
    onSuccess: (data) => {
      if (data) {
        const childrenChanged = !isEqual(
          sortBy(data.folders.map((f) => f.id)),
          sortBy(folder.children?.map((f) => f.id)),
        );
        if (childrenChanged) {
          folder.children = data.folders.sort((a, b) => a.name?.localeCompare(b.name));
        }
        if (folder.children) {
          // Sort daily shows in order of air time
          folder.children = folder.children.sort(sortDailyShows);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  function persistCurrentFolder(current: { folder?: Folder; parent?: Folder }) {
    const entry = {
      folder: omit(current.folder, ["images"]),
      parent: omit(current.parent, ["children"]),
    };
    $recentFolders.push(entry);
    localStorage.setItem("recentFolders", JSON.stringify(takeRight($recentFolders, 3)));
  }

  const toggleExpansion = async (folderId: string) => {
    if ((folder.children && folder.children.length > 0) || expandLeafFolder) {
      expanded = !expanded;
      expanded ? $expandedFolders.add(folderId) : $expandedFolders.delete(folderId);
    } else {
      persistCurrentFolder({ folder, parent });
      if (isEmbedded()) {
        $productFruitsInstance?.api.events.track("folder-selected-embedded");
        setFolderFilter(folderId);
        expanded = false;
      } else {
        $productFruitsInstance?.api.events.track("folder-selected-standalone");
        dispatch("select", { folder, parent, expanded });
      }
    }
  };

  async function _deleteFolder(folderId: string) {
    const response = await deleteFolder(folderId).catch((error) =>
      console.error(`Failed to delete folder '${folderId}'`, error, response),
    );
    nodeRef.parentNode?.removeChild(nodeRef);
  }

  async function _createFolder(name: string, parentId: string) {
    const newFolder = await createFolder(name, parentId);
    if (folder.children) {
      folder.children = [
        ...folder.children,
        {
          name: newFolder.name,
          id: newFolder.id,
          children: [],
          images: [],
          expanded: false,
          hasSubFolder: false,
          locked: newFolder.locked,
        } as Folder,
      ];
    }
  }
  async function _updateMetadataToFolder(folderId: string, metadata: Partial<FolderMetadata>) {
    const updatedFolder = await updateFolder(folderId, metadata);
    folder.name = updatedFolder.name;
  }

  let nodeRef: Node;

  onDestroy(() => {
    $query.remove();
  });

  let mounted = false;
  onMount(() => {
    mounted = true;
    if ($todaysFolderInitiallyOpen && folder.name === format(new Date(), "yyyy-MM-dd")) {
      $expandedFolders.add(folder.id);
      $todaysFolderInitiallyOpen = false;
    }
  });

  export let expandLeafFolder = false;

  let showExpandButton = expandLeafFolder && !isEmbedded();
  let showContextMenu = expandLeafFolder && !isEmbedded();

  const dispatch = createEventDispatcher();
  let dragTimer: ReturnType<typeof setTimeout>;
</script>

<li bind:this={nodeRef}>
  {#if !contentOnly}
    <div class="header">
      <div>
        <button
          class="org-button"
          class:drag-highlight={draggingOverHeader}
          on:click={() => {
            toggleExpansion(folder.id);
          }}
        >
          <span class="expand-wrapper">
            {#if showExpandButton || (folder.children && folder.children.length > 0) || parsedTitleDate}
              {#if expanded}
                {@html orgChevronOpen}
              {:else}
                {@html orgChevronClosed}
              {/if}
            {/if}
          </span>
          {parsedTitle}
        </button>

        {#if mounted && $query.isLoading}
          <span in:fade={{ duration: 0, delay: 1000 }} class="org-spinner spinner"></span>
        {/if}
      </div>
      {#if showContextMenu}
        <button class="org-button context-menu">{@html orgDotdotdot}</button>
        <bb-dropdown hidden>
          {#if !folder.children?.length}
            <button
              class="org-button"
              on:click={() => {
                $productFruitsInstance?.api.events.track("expand-folder");
                setFolderFilter(folder.id);
              }}
            >
              {@html orgExternalLink} Åpne
            </button>
          {/if}
          {#if !folder.images?.length}
            <NewFolderButton onConfirm={(name) => _createFolder(name, folder.id)} />
          {/if}
          <EditFolderButton
            {folder}
            onConfirm={(folderMetadata) => _updateMetadataToFolder(folder.id, folderMetadata)}
          />
          {#if !(folder.children && folder.children.length > 0)}
            <button
              class="org-button"
              on:click={() => {
                _deleteFolder(folder.id);
              }}
              disabled={folder.locked || (folder.children && folder.children.length > 0)}
            >
              {@html orgGarbage} Slett
            </button>
          {/if}
        </bb-dropdown>
      {/if}
    </div>
  {/if}
  {#if expanded}
    {#if !(folder.children && folder.children?.length > 0)}
      <FolderImages folderId={folder.id} />
    {/if}

    {#if expanded && folder.children && $query.isSuccess}
      {#each folder.children as child (child.id)}
        <svelte:self folder={child} parent={folder} {expandLeafFolder} bind:current on:select />
      {/each}
    {/if}
  {/if}
</li>

<style>
  li {
    list-style: none;
    margin-left: var(--org-small);
  }
  .org-spinner:after {
    color: white;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .spinner {
    font-size: 0.5rem;
    margin-left: 1rem;
  }
  .expand-wrapper {
    display: inline-block;
    min-width: 1.5rem;
    font-size: 0.75rem;
  }
  .header .context-menu {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s visibility 0.5s;
  }
  .header:hover .context-menu {
    opacity: 1;
    visibility: visible;
  }
  .drag-highlight {
    background-color: var(--org-color-shade-1, hsla(210, 15%, 50%, 0.2));
  }
</style>
